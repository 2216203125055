import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';

export default function IDKAPage() {
  const [myMoist, setMyMoist] = useState(0); // Initialize with 0 instead of 1
  const socketRef = useRef(null);

  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const response = await axios.get('https://lukasotterstrom.com/api/user/moistureget');
        const initialValue = parseFloat(response.data);
        setMyMoist(initialValue.toFixed(2));
      } catch (error) {
        console.error('Error fetching initial value:', error);
      }
    };

    fetchInitialValue();
  }, []);

  useEffect(() => {
    socketRef.current = new WebSocket('wss://lukasotterstrom.com/moisturews');
    socketRef.current.onopen = () => {
      console.log('WebSocket connection opened');
    };

    socketRef.current.onmessage = (event) => {
      const data = parseFloat(event.data);
      setMyMoist(data.toFixed(2));
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      socketRef.current.close();
    };
  }, []);

  const closeIdka = () => {
    $('#idkaPop').css('opacity', '0');
    $('#idkaPop').css('pointer-events', 'none');
  };

  return (
    <div id="idkaPop">
      <div id="idkaWindow" className='col-lg-3 col-md-6 col-11 container-fluid position-relative'>
        <div onClick={closeIdka}>
          <i className='fa fa-times' style={{ color: "white", fontSize: "70px", position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}></i>
        </div>
        <div id="moisture">{myMoist}</div>
      </div>
    </div>
  );
}
