import React from 'react';
import { useState } from 'react';
import axios from "axios";
import Burger from '../Burger';
import $ from 'jquery';

function LogOutPage(){
const closeLogOut = () =>{
    $('#logoutPop').css('opacity', '0');
    $('#logoutPop').css('pointer-events', 'none');
};
const removeToken = () =>{
    localStorage.removeItem('token');
    closeLogOut();
};
return(
<div id="logoutPop">
      <div id="logoutwindow" className='col-lg-3 col-md-6 col-11 container-fluid position-relative'>
      <h2 style={{color: "white"}} className='text-center py-4'>Are you sure you want to log out?</h2> 
        <div className='row justify-content-between px-5 pb-4'>
        <button className='btn btn-primary col-lg-3 col-5' onClick={removeToken}>Log Out</button>
        <button className='btn btn-primary col-lg-3 col-5' onClick={closeLogOut}>Cancel</button>
        </div>
      </div>
    </div>
);
}

export default LogOutPage;