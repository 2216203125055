import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import $ from 'jquery';

function Burger(props) {

  const token = localStorage.getItem("token");
  const tokenExists = token !== null;

  const mainMenu = $('#mainMenu');

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 768;
  const isTablet = /iPad|Android(?!.*Mobile)/i.test(navigator.userAgent) || (window.innerWidth >= 768 && window.innerWidth < 1024);
  
  const mainMenuLeft = useRef('0');
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    
    if(isMobile && !isTablet){
      mainMenuLeft.current = !isOpen ? '-260px' : '0';
      mainMenu.css('top', mainMenuLeft.current);
    }else{
      mainMenuLeft.current = !isOpen ? '-500px' : '0';
      mainMenu.css('left', mainMenuLeft.current);
      mainMenu.css('top', '30px');
    }

    const handleClickOutside = (event) => {
      const mmenu = document.getElementById('mainMenu');
      const burgerm = document.getElementById('burgerCon');
      const cross = document.getElementById('menuCross');

      if (burgerm.contains(event.target)) {
        setIsOpen(!isOpen);
      
      } else if (event.target === cross || event.target !== mmenu){
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [isOpen, mainMenu, mainMenuLeft]);

  const showLogin = () =>{
    var loginwindow = $('#loginPop');
    loginwindow.css('opacity', '1');
    loginwindow.css('pointer-events', 'auto');
    setIsOpen(false);
  };
  const showSignUp = () => {
    var signupwindow = $('#signupPop');
    signupwindow.css('opacity', '1');
    signupwindow.css('pointer-events', 'auto');
    setIsOpen(false);
  };
  const showLogOut = () => {
    var logoutwindow = $('#logoutPop');
    logoutwindow.css('opacity', '1');
    logoutwindow.css('pointer-events', 'auto');
    setIsOpen(false);
  };
  const showNewPost = () => {
    var postwindow = $('#postPop');
    postwindow.css('opacity', '1');
    postwindow.css('pointer-events', 'auto');
    setIsOpen(false);
  };
  const showFragment = (fragment) =>{
    const element = document.getElementById(`my${fragment}`);
    if(element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };
  
  const href = window.location.href.split('/');
  const location = href[href.length-1];

  if(location == "community"){
    return(
<>
        <div className='menuCon'>
      <div id="mainMenu">
        <div className="menuCloseIcon">
        <i className='fa fa-times' id="menuCross" style={{color: "white", fontSize: "40px", position: "absolute", top: "10px", left: "20px"}}></i>
        </div>
        <div className='col-lg-8 col-md-8 col-12 offset-lg-4 offset-md-4 lineMenuCon'>

          {token ? (<div>
            <Link style={{ textDecoration: 'none' }} to="/profile"><div className='menuLink mt-3'>Profile</div></Link>
            <div className='menuLink mt-3' onClick={showNewPost}>New post</div>
            <div className='menuLink mt-3' onClick={showLogOut}>Log Out</div>
            
          </div>) :
          (<div>
            <div className='menuLink mt-3' onClick={showLogin}>Log In</div>
          <div className='menuLink mt-3' onClick={showSignUp}>Sign Up</div>
          </div>)}

          <Link style={{ textDecoration: 'none' }} to="/"><div className='menuLink my-3' style={{color: "#c560ff"}}>Home Page</div></Link>
        </div>
      </div>
      <div id="burgerCon"> 
        <div className='patty'></div>
        <div className='patty'></div>
        <div className='patty'></div>
      </div>
    </div>
    
    </>
    );
  
  } else {
  return (
    <>
     <div className='menuCon'>
      <div id="mainMenu">
        <div className="menuCloseIcon">
        <i className='fa fa-times' id="menuCross" style={{color: "white", fontSize: "40px", position: "absolute", top: "10px", left: "20px"}}></i>
        </div>
        <div className='col-lg-8 col-md-6 offset-lg-4 offset-md-4 lineMenuCon'>
          <div className='menuLink mt-3' onClick={()=>{showFragment('Contact')}}>Contact</div>
          <div className='menuLink mt-3' onClick={()=>{showFragment('Info')}}>Info</div>
          <Link to="/community" style={{ textDecoration: 'none' }}>
            <div className='menuLink my-3' style={{color: "#c560ff"}}>
              Community
            </div>
          </Link>
        </div>
      </div>
      <div id="burgerCon"> 
        <div className='patty'></div>
        <div className='patty'></div>
        <div className='patty'></div>
      </div>
    </div>
    </>
  );
}
}

export default Burger;