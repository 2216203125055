import React, {useEffect} from 'react';
import $ from 'jquery';
import SlideShow from '../slideshow';
import LoginPage from './login';
import SignUpPage from './signup';
import LogOutPage from './logout';
import PostPage from './newpost';
import ShowPostPage from './showpost';

const blogpostList = [];

const showPost = (post) => {
  $('#showpostPop').css('opacity', '1');
  $('#showpostPop').css('pointer-events', 'auto');
  blogpostList.forEach(blogPost => {
    if(blogPost.id == post.id){
      $('#postTitle').text(post.title);
      $('#postSubTitle').text("By " + post.poster + " "+post.datePosted.slice(0, 10))
      var contentWithLineBreaks = post.content.replace(/\n/g, "<br>");
      $('#postContent').html(contentWithLineBreaks);
    }
  });
};

const populateBlogView = (blogList) =>{
  const reverseList = blogList.reverse();
  reverseList.forEach(post => {
    const newPost = document.createElement('div');
    newPost.className = 'newPost mb-2';
    var txcnt = '"'+post.title+'" by '+post.poster+'. Posted '+post.datePosted.slice(0, 10)+'.';
    newPost.textContent = txcnt;
    newPost.addEventListener('click', ()=>{
      showPost(post);
    });
    newPost.style.cursor = 'pointer';
    const parentDiv = document.querySelector('.comFeed');
    parentDiv.appendChild(newPost);
    blogpostList.push(post);
  });
};

export default function CommunityPage() {

useEffect(()=>{
    fetch('https://lukasotterstrom.com/api/user/getposts')
    .then(response => response.json())
    .then(data => {
      populateBlogView(data);
    })
    .catch(error => console.error(error));
  },[])

  return (
    <>
    <ShowPostPage/>
    <LoginPage/>
    <SignUpPage/>
    <LogOutPage/>
    <PostPage/>
    <SlideShow/>
    <div id="mainPage">
      <div className='row' id="mainPage1">
        <p id="myName">Community</p>
        <p>Create account and start posting!</p>
        <li className='mb-3'>Latest posts:</li>
        <div className="comFeed col-lg-3 col-md-6">
        </div>
      </div>
    </div>
    <div className='filler'></div>
    </>
  );
}