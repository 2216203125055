import React from 'react';
import $ from 'jquery';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as openpgp from 'openpgp';
import IDKAPage from './idka';

async function encryptData(data) {
  try {
    const response = await fetch('https://lukasotterstrom.com/api/user/scrypts/public');
    
    const publicKeyArmored = await response.text();
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: JSON.stringify(data) }),
      encryptionKeys: publicKey
    });
    return encrypted;
  } catch (error) {
    console.error(error);
  }
};
async function saveDetails(){
  $('#profileDOB').text($('#profileDOBPicker').val());
  $('#profileDOBPicker').css('display', 'none');
  $('#profileDOB').css('display', 'block');


  const data = {
    "Firstname" : $('#profileFirstName').text(),
    "Lastname": $('#profileLastName').text(),
    "Birthday": $('#profileDOBPicker').val(),
    "Username": $('#profileUserName').text(),
    "Email": $('#profileEmail').text(),
    "Phone": $('#profileNumber').text(),
    "Id" : userId2
  }

  try{
    const encrypted = await encryptData(data);
    fetch('https://lukasotterstrom.com/api/user/changedetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(encrypted)
      })
      .then(response => {
      if (response.ok) {
        alert('Details updated!');
        $('.profileTextBox').css('border', '0');
        $('.profileTextBox').attr('contenteditable', false);
        $('#changeButton').show();
        $('#saveButton').hide();
      } else {
        throw response;
      }
      })
      .catch(error => {
      error.text().then(errorMessage => {
        const messageObj = JSON.parse(errorMessage);
        alert(`${messageObj.message}`);
      });
      });
      

  }catch(error){
    console.error(error);
    alert("An error occurred. Please try again later.");
  }
};
async function ultChangePW(){
  var correctPW1 = ($('#oldpw').val() == $('#comfoldpw').val()) && ($('#newpw').val() == $('#comfnewpw').val());

  if(correctPW1){
    const data = {
      "Username" : $('#profileUserName').text(),
      "OldPassword" : $('#oldpw').val(),
      "NewPassword" : $('#newpw').val()
    };
    
      const encrypted = await encryptData(data);
      fetch('https://lukasotterstrom.com/api/user/changepw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(encrypted)
      })
      .then(response => {
        if (response.ok) {
          
          alert('Password updated!');
          $("input").val("");
        } else if (response.status === 401) {
          throw new Error(response.json());
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch(error => {
        error.text().then(errorMessage => {
          alert(errorMessage);
        });
      });
      
    
  }else{
    alert("Passwords does not match.");
  }
  
};

var userId2;

const changeDetails = () =>{
  $('#profileDOBPicker').val( $('#profileDOB').text());
  $('#profileDOBPicker').css('display', 'block');
  $('#profileDOB').css('display', 'none');
  
  $('.profileTextBox').css('border', '3px solid #0275d8');
  $('.profileTextBox').attr('contenteditable', true);
  $('#changeButton').hide();
  $('#saveButton').show();
};

const changePW = () => {
  $('#changePWpop').css('opacity', '1');
  $('#changePWpop').css('pointer-events', 'auto');
};

const closePWwindow = () =>{
  $('#changePWpop').css('opacity', '0');
  $('#changePWpop').css('pointer-events', 'none');
};

const showIdka = () =>{
  $('#idkaPop').css('opacity', '1');
  $('#idkaPop').css('pointer-events', 'auto');
};

const lights = () =>{
  fetch("https://shelly-68-eu.shelly.cloud/device/status?id=4022d882e7eb&auth_key=MTkxNzhjdWlk68F8F69A6A1DF53813683ABF321EB82E409B2F3999EE1EAD77CC30E7465375D6FD1256639EBC1BAC", {
    method: 'POST',
    redirect: 'follow'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      const isOn = data.data.device_status.relays[0].ison;
      const newStatus = isOn ? "off" : "on";
  
      var formdata = new FormData();
      formdata.append("id", "4022d882e7eb");
      formdata.append("auth_key", "MTkxNzhjdWlk68F8F69A6A1DF53813683ABF321EB82E409B2F3999EE1EAD77CC30E7465375D6FD1256639EBC1BAC");
      formdata.append("turn", newStatus);
      formdata.append("channel", "0");
  
      var requestOptions2 = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      const switchFetch = () =>{
        fetch("https://shelly-68-eu.shelly.cloud/device/relay/control", requestOptions2)
        .then(response => response.text())
        .catch(error => console.log('error', error));
      };
  
      setTimeout(switchFetch, 2000);
      
    })
    .catch(error => console.log('error', error));  
};

export default function ProfilePage() {
  const token = localStorage.getItem("token");
  const parts = token.split(".");
  const payload = JSON.parse(window.atob(parts[1]));
  const userId = payload.userid;
  userId2 = userId;
  const validToken = userId2 != "" || userId2 != null;
  
  var isLukas = userId == "60f7001f-2d94-4150-a312-bc7f398893fc";

  const [showPassword, setShowPassword] = useState(false);
  const [lightStatus, setLightStatus] = useState('');

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleLightStatus = () => {
    setLightStatus(!lightStatus);
  };

  useEffect(()=>{

    
    if(isLukas){
    fetch("https://shelly-68-eu.shelly.cloud/device/status?id=4022d882e7eb&auth_key=MTkxNzhjdWlk68F8F69A6A1DF53813683ABF321EB82E409B2F3999EE1EAD77CC30E7465375D6FD1256639EBC1BAC", {
      method: 'POST',
      redirect: 'follow'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      const isOn = data.data.device_status.relays[0].ison;
      setLightStatus(isOn);
    })
    .catch(error => console.log('error', error));
  }

    fetch(`https://lukasotterstrom.com/api/user/profile?Id=${userId}`)
    .then(response => response.json())
    .then(data => {
      $('#profileFirstName').text(data.name);
      $('#profileLastName').text(data.lastName);
      $('#profileDOB').text(data.birthDay.slice(0, 10));
      $('#profileUserName').text(data.userName);
      $('#profileEmail').text(data.email);
      $('#profileNumber').text(data.phone);
    })
    .catch(error => console.error(error));
  },[])

  const buttonText = showPassword ? "Hide text" : "Show text";
  const inputType = showPassword ? "text" : "password";
  const lightButtonText = lightStatus ? "off" : "on";
    return (
      <>
      {validToken ? (
        <>
        <IDKAPage/>
        <img id="profileBG" src="/wallhaven-0q6ok7.jpg"/>
      <div className='container-fluid' id="profileContainer">

      <div id="changePWpop">
        <div className="col-lg-4 col-md-7 col-10 mt-0 position-relative" id="changePWwindow">
          <i onClick={closePWwindow} className="fa fa-times position-absolute top-0 end-0 m-3" style={{ color: "white", fontSize: "40px", cursor: "pointer" }}></i>
          <h2 className="text-start px-4 py-4">Change password</h2>
          
          <div className="row justify-content-center">
            <div className="col-10 mb-3">
              <label htmlFor="oldpw" className="form-label">Old password</label>
              <input type={inputType} id="oldpw" className="form-control" />
            </div>
            <div className="col-10 mb-3">
              <label htmlFor="comfoldpw" className="form-label">Confirm old password</label>
              <input type={inputType} id="comfoldpw" className="form-control" />
            </div>
          </div>
          <div className="row justify-content-center mb-4 mt-5">
            <div className="col-10 mb-3">
              <label htmlFor="newpw" className="form-label">New password</label>
              <input type={inputType} id="newpw" className="form-control" />
            </div>
            <div className="col-10 mb-3">
              <label htmlFor="comfnewpw" className="form-label">Confirm new password</label>
              <input type={inputType} id="comfnewpw" className="form-control" />
            </div>
          </div>
          <div className="row justify-content-evenly mb-4">
          <button onClick={toggleShowPassword} className="btn btn-primary col-lg-4 col-md-4 col-8">
              {buttonText}
            </button>
            <button className="btn btn-primary col-lg-4 col-md-4 col-8 mt-lg-0 mt-md-0 mt-4" onClick={ultChangePW}>Change password</button>
          </div>
        </div>
      </div>

        <div id="profileWindow" className='col-lg-6 container-fluid pb-5 mb-4'>
          
            <Link to="/community"><i className='fa fa-arrow-left position-absolute' style={{top: "6vh", fontSize: "40px"}}></i></Link>
            <p className='text-center pt-3' style={{fontSize: "40px"}}>Profile</p>
            <div className='line col-lg-6 col-8 container-fluid mt-3 mb-4'></div>

            

             <div className='container-fluid col-lg-10 col-12'>
             <h3 className="text-start col-10 mt-5 mb-3">Personal details</h3>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 text-start'>
                <div>First name</div>
                <div id="profileFirstName" className='profileTextBox mt-1'></div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 text-start mt-lg-0 mt-md-0 mt-3'>
                  <div>Last name</div>
                <div id="profileLastName" className='profileTextBox mt-1'></div>
                </div>
              </div>
            </div>

            <div className='container-fluid col-lg-10 col-12 mt-2 mt-lg-4'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 text-start mt-3'>
                <div>Date of birth</div>
                <div id="profileDOB"  className='profileTextBox mt-1'></div>
                <input type="date" id="profileDOBPicker" className='profileTextBox mt-1'></input>
                </div>
              </div>
            </div>

            
            <div className='container-fluid col-lg-10 col-12'>
            <h3 className="text-start col-10 mt-5 mb-3">User details</h3>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 text-start'>
                <div>Username</div>
                <div id="profileUserName" className='profileTextBox mt-1'></div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 text-start mt-lg-0 mt-md-0 mt-3'>
                  <div>E-mail</div>
                <div id="profileEmail"  className='profileTextBox mt-1'></div>
                </div>
              </div>
            </div>
            

            <div className='container-fluid col-lg-10 col-12 mt-2 mt-lg-4'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 text-start mt-3'>
                <div>Phone number</div>
                <div id="profileNumber" className='profileTextBox mt-1'></div>
                </div>
              </div>
            </div>

          
            <div className='row justify-content-md-evenly justify-content-around px-lg-5 px-md-5 px-3' style={{marginTop: "60px"}}>
              <button id="changeButton" className='btn btn-primary col-md-4 col-10 mb-lg-0 mb-md-0 mb-4' onClick={changeDetails}>Change details</button>
              <button id="saveButton" className='btn btn-warning col-md-4 col-10 mt-lg-0 mb-lg-0 mb-md-0 mb-4' onClick={saveDetails}>Save changes</button>
              <button className='btn btn-primary col-md-4 col-10' onClick={changePW}>Change password</button>
              </div> 
              {isLukas ? (
                <div className='row justify-content-md-evenly justify-content-around px-lg-5 px-md-5 px-3 mt-5'>
              <button className='btn btn-primary col-md-4 col-10 mt-lg-0 mb-lg-0 mb-md-0 mb-4' 
              onClick={()=>{
                lights(); 
                toggleLightStatus();
              }}>Lights {lightButtonText}</button>
              <button className='btn btn-primary col-md-4 col-10' onClick={showIdka}>IDKA</button>
              </div>
              ) 
              : (<></>)}
            
        </div>
      </div></>) : (
        <div onLoad={window.location = "../"}></div>
      )}
      </>);
};