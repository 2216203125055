import React from 'react';
import { useState } from 'react';
import axios from "axios";
import Burger from '../Burger';
import $ from 'jquery';

function PostPage(){

const closePost = () =>{
    $('#postPop').css('opacity', '0');
    $('#postPop').css('pointer-events', 'none');
};

const createPost = () =>{
  const token = localStorage.getItem("token");
  const parts = token.split(".");
  const payload = JSON.parse(window.atob(parts[1]));
  const userId = payload.userid;


  if($('#title').val() != "" && $('#message').val() != ""){

 
  const data = {
    "Title" : $('#title').val(),
    "Content" : $('#message').val(),
    "UserID" : userId
  };
  fetch('https://lukasotterstrom.com/api/user/createpost', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => {
    if(response.ok){
      alert("Post created!");
      $('#title').val("");
      $('#message').val("");
      closePost();
    } else {
      throw response;
    }
  })
  .catch(error => {
  
      alert("Something went wrong.");
   
  });
} else {
  alert("Please fill the fields.");
}
};

return(
<div id="postPop">
      <div id="postwindow" className='col-lg-4 col-md-8 col-11 container-fluid position-relative'>
      <i className='fa fa-times' style={{cursor: "pointer", color: "white", fontSize: "40px", position: "absolute", top: "10px", right: "20px"}} onClick={closePost}></i>
      <h2 style={{color: "white"}} className='text-lg-center text-md-center py-4 px-4'>Create new post</h2> 
       
              <div className='row'> 
                <div className='col-lg-8 col-10 text-start container-fluid'>
                  <label htmlFor="title">Title</label>
                  <input type="text" className="form-control mt-2" id="title"/>
                </div>
                </div>
                <div className='row mt-5'> 
                <div className='col-lg-8 col-10 text-start container-fluid'>
                <label htmlFor="message">Message</label>
                <textarea type="text" className="form-control mt-2" name="message" id="message" style={{height: "200px"}}/>
                <button onClick={createPost} className="btn btn-primary my-5">Create post</button>
                </div>
                
              </div>
        
      </div>
    </div>
);

}

export default PostPage;