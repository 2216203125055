import Burger from "./Burger";
import $ from 'jquery';
import React, { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';


const gradSet = (index) =>{
    var gradList = [
        $('.gradleft1'),
        $('.gradright1'),
        $('.gradleft2'),
        $('.gradright2'),
        $('.gradleft3'),
        $('.gradright3'),
        $('.gradleft4'),
        $('.gradright4')
        ];
        
    if(index == 1){
        gradList[1].css('opacity', 1);
        setTimeout(()=>{
            gradList[2].css('opacity', 0);
        },2000);
    }else if(index == 2){
        gradList[3].css('opacity', 1);
        setTimeout(()=>{
            gradList[4].css('opacity', 0);
        },2000);
    }else if(index == 3){
        gradList[5].css('opacity', 1);
        setTimeout(()=>{
            gradList[6].css('opacity', 0);
        },2000);
    }else if(index == 0){
        gradList[7].css('opacity', 1);
        gradList[0].css('opacity', 1);
        gradList[1].css('opacity', 0);
        setTimeout(()=>{
            gradList.forEach((element, index) => {
                if (index % 2 === 0) {
                    element.css('opacity', 1)
                  } else {
                    element.css('opacity', 0)
                  }
            });
            gradList[0].css('opacity', 0);
        },2000);
        
        
    }
};

export default function SlideShow(){
    useEffect(()=>{
        var gradList = [
            $('.gradleft1'),
            $('.gradright1'),
            $('.gradleft2'),
            $('.gradright2'),
            $('.gradleft3'),
            $('.gradright3'),
            $('.gradleft4'),
            $('.gradright4')
            ];
        gradList.forEach((element, index)=>{
            if(index == 0 || index % 2 != 0){
                element.css('opacity', 0)
            } else if (index % 2 == 0){
                element.css('opacity', 1)
            }
        });
    },[]);
    const href = window.location.href.split('/');
    const location = href[href.length-1];
    if(location == "community"){
    return(

        
    <div className='screenContainer'>
        <Burger/> 
        
        <Carousel interval={4000} onSlide={(index) =>{
            gradSet(index);
        }}>
        <Carousel.Item>
            <div className="piccon">
            <div className='gradleft1'></div>
            <img className="d-block w-100" src="/wallhaven-gjo67qy.jpg" alt="Image 1" />
            <div className='gradright1'></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className="gradleft2"></div>
            <img className="d-block w-100" src="/wallhaven-9dz1oxy.jpg" alt="Image 2" />
        <div className="gradright2"></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className="gradleft3"></div>
            <img className="d-block w-100" src="/wallhaven-1pq6zgy.jpg" alt="Image 3" />
        <div className="gradright3"></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className='gradleft4'></div>
            <img className="d-block w-100" src='/wallhaven-01z6rvy.jpg' alt="Image 4" />
        <div className='gradright4'></div>
            </div>
        </Carousel.Item>
        </Carousel>
    </div>
    );
    } else {
    return( 
    <div className='screenContainer'>
    <Burger/> 
    <Carousel interval={4000} onSlide={(index) =>{
            gradSet(index);
        }}>
        <Carousel.Item>
            <div className="piccon">
            <div className='gradleft1'></div>
            <img className="d-block w-100" src="/wallhaven-8x8xo2.jpg" alt="Image 1" />
            <div className='gradright1'></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className='gradleft2'></div>
            <img className="d-block w-100" src="/wallhaven-wym5jx.png" alt="Image 2" />
            <div className='gradright2'></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className='gradleft3'></div>
            <img className="d-block w-100" src="wallhaven-xl8w7l.jpg" alt="Image 3" />
            <div className='gradright3'></div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="piccon">
        <div className='gradleft4'></div>
            <img className="d-block w-100" src='wallhaven-eyvm1r.jpg' alt="Image 4" />
            <div className='gradright4'></div>
            </div>
        </Carousel.Item>
        </Carousel>
    </div>
    );
}}