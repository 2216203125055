import React, { useState } from 'react';
import ProfilePage from './pages/profile';
import HomePage from './pages/home';
import LoginPage from './pages/login'
import SignUpPage from './pages/signup';
import CommunityPage from './pages/community';
import PortfolioPage from './pages/portfolio';
import $ from 'jquery';
import axios from "axios";
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <div>
      <Router>
      {/* <Menu /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/community" element={<CommunityPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/community/login" element={<LoginPage />} />
          <Route path="/community/signup" element={<SignUpPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;