import React from 'react';
import $ from 'jquery';

const closeShowPost = () =>{
    $('#showpostPop').css('opacity', '0');
    $('#showpostPop').css('pointer-events', 'none');
};


export default function ShowPostPage(){

return(
<div id="showpostPop">
      <div id="showpostWindow" className='col-lg-4 col-md-8 col-11 container-fluid position-relative'>
      <div onClick={closeShowPost}>
      <i className='fa fa-times' style={{color: "white", fontSize: "30px", position: "absolute", top: "10px", right: "20px", cursor: "pointer"}}></i>
      </div>
      <h2 className='text-center pt-4' id="postTitle"></h2>
      <h4 className="text-center pb-4" id="postSubTitle"></h4>
      <div id="postContent" className='container-fluid col-11'>hfgf</div>
      </div>
    </div>
);
}