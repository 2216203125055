import React from 'react';
import { useState } from 'react';
import $, { error } from 'jquery';
import * as openpgp from 'openpgp';

async function encryptData(data) {
  try {
    const response = await fetch('https://lukasotterstrom.com/api/user/scrypts/public');
    
    const publicKeyArmored = await response.text();
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: JSON.stringify(data) }),
      encryptionKeys: publicKey
    });
    return encrypted;
  } catch (error) {
   
  }
}

async function postSignUp(data) {
 
    const encrypted = await encryptData(data);
    const response = await fetch('https://lukasotterstrom.com/api/user/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(encrypted)
    });
    if (!response.ok) {
      
        const errorData = await response.json();
        alert(errorData.message);
        throw new Error(errorData.message);
      
    }
    const responseData = await response.json();
    alert("User created!");
    var signupwindow = $('#signupPop');
    signupwindow.css('opacity', '0');
    signupwindow.css('pointer-events', 'none');
    return responseData;
 
};

function SignUpTabContent(){

    const [formData, setFormData] = useState({
      userName: '',
      eMail: '',
      passWord: '',
    });
  
    const handleChange = (event) => {
      const { id, value } = event.target;
        setFormData({
          ...formData,
          [id]: value
        });
      
    ;}

    const handleSubmit =  (event) => {
    
      const data = {
        "Username": $('#userName').val(),
        "Email": $('#eMail').val(),
        "Password": $('#passWord').val()
      }

      const fieldsFilled = Object.values(data).every(value => value !== "");

      const validEmail = (email) =>{
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
      };
  
      event.preventDefault();
      if(!fieldsFilled){
        alert("All fields must be filled.");
      } else if($('#passWord').val() != $('#password2').val()){
        alert("Passwords don't match.");
      } else if(!validEmail($('#eMail').val())){
        alert("Invalid email.")
      } else{
        postSignUp(data);
      }
    };
  
  return <>
  <div className='container-fluid col-lg-12 col-md-10 col-12 mt-5 mb-5' style={{color: "white"}}>
  
  <h2 style={{color: "white"}}>Sign Up</h2>  
  <form onSubmit={handleSubmit} > 
  
          <div className='container-fluid col-12 p-0 mt-4'>
              <div className='row'> 
                <div className='col-lg-6 col-12 text-start'>
                <label htmlFor="username">Username</label>
                <input type="text" className="form-control mt-2" id="userName" defaultValue={formData.userName} onChange={handleChange}/>
                </div>
                <div className='col-lg-6 col-12 text-start mt-lg-0 mt-3'>
                <label htmlFor="email">E-mail</label>
                <input type="text" className="form-control mt-2" id="eMail" defaultValue={formData.eMail} onChange={handleChange}/>
                </div>
              </div>
            </div>
  
          <div className='container-fluid col-12 mt-lg-4 mt-5 p-0'>
              <div className='row'> 
                <div className='col-lg-6 col-12 text-start'>
                <label htmlFor="password1">Password</label>
                <input type="password" className="form-control mt-2" id="passWord" defaultValue={formData.passWord} onChange={handleChange}/>
                </div>
                <div className='col-lg-6 col-12 text-start mt-lg-0 mt-3'>
                <label htmlFor="password2">Comfirm password</label>
                <input type="password" className="form-control mt-2" id="password2" name="password2" />
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-5">Create Account</button>
            </div>
          </form>
    </div>
         
  </>;
  }

export default function SignUpPage(){
  const closeSignUp = () =>{
    $('#signupPop').css('opacity', '0');
    $('#signupPop').css('pointer-events', 'none');
  };

  
return (
  <div id="signupPop">
      <div id="signupwindow" className='col-lg-5 col-md-8 col-11 container-fluid position-relative px-4'>
        <div onClick={closeSignUp}>
      <i className='fa fa-times' style={{color: "white", fontSize: "70px", position: "absolute", top: "10px", right: "20px",cursor: "pointer"}}></i>
      </div>
        <SignUpTabContent/>
      </div>
    </div>
    );
};