import React, { useEffect, useState } from 'react';
import SlideShow from '../slideshow';
import $ from 'jquery';
import emailjs from 'emailjs-com';

const downLoad = (language) =>{
    let fileUrl, fileName;
    if (language === "sv") {
        fileUrl = "https://lukasotterstrom.com/files/CV Lukas Otterström SV.pdf";
        fileName = "CV Lukas Otterström SV.pdf";
    } else if (language === "en") {
        fileUrl = "https://lukasotterstrom.com/files/CV Lukas Otterström EN.pdf";
        fileName = "CV Lukas Otterström EN.pdf";
    }
    
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const handleEmail = (event) =>{

    const name = $('#formname').val();
    const email = $('#formemail').val();;
    const phone = $('#formphone').val();;
    const message = $('#formmessage').val();;

  event.preventDefault();
  const templateParams = {
    name: name,
    email: email,
    phone: phone,
    message: message,
  };

  // Send the email using the SMTP server
  emailjs.sendForm('service_xjao16w', 'template_2r8zftf', event.target, 'tQi5rJus0tiWCKWzg')
  .then((result) => {
    alert("Email sent successfully!");
    window.location.reload();
    console.log(result.text);
  }, (error) => {
    alert("An error occured.");
    console.log(error.text);
  });
};

export default function HomePage() {
    const [myStatus, setStatus] = useState("");
    const token = localStorage.getItem("token");
    var isAntonia = false;
    useEffect(()=>{
        fetch("https://shelly-68-eu.shelly.cloud/device/status?id=4022d882e7eb&auth_key=MTkxNzhjdWlk68F8F69A6A1DF53813683ABF321EB82E409B2F3999EE1EAD77CC30E7465375D6FD1256639EBC1BAC", {
            method: 'POST',
            redirect: 'follow'
            })
            .then(response => {
                if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const isOn = data.data.device_status.relays[0].ison;
                setStatus(isOn ? "på" : "av");
            })
            .catch(error => console.log('error', error)); 
            
        },[]);
    if(token != null){
        const parts = token.split(".");
        const payload = JSON.parse(window.atob(parts[1]));
        const userId = payload.userid;
        isAntonia = userId == "e8bf4c92-10ad-4a86-3754-08db4340c612";    
    }
    
    

    return (
        <>
       
        <SlideShow/>
        <div id="mainPage">
            <div className='row' id="mainPage1">
            <p id="myName">Lukas Otterström{isAntonia ? (<>s lampa är {myStatus}</>) : (<></>)}</p>
            <p>Junior developer & future web designer and system developer.</p>
        
            </div>
            <div className='line col-lg-4 col-10 mb-5'></div>
            <div className='row' id="mainPage2">
            
            <p id="myContact">Contact</p>
            {/* fba67f9q4bhdy9asv0 */}
            <form onSubmit={handleEmail} id="contactForm">
                  <div className='container-fluid p-0'>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control mt-2" name="name" id="formname"/>
                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="email">Email</label>
                        <input type="text" className="form-control mt-2" name="email" id="formemail"/>
                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="phone">Phone number</label>
                        <input type="text" className="form-control mt-2" name="phone" id="formphone"/>
                    </div>
                    <div className="form-group mt-2">
                        <label htmlFor="message">Message</label>
                        <textarea type="text" className="form-control mt-2" name="message" style={{height: "200px"}} id="formmessage"/>
                    </div>
                    <button type="submit" className="btn btn-primary mt-4">Send message</button>
                    </div>
                    </form>
                

            </div>
            <div className='line col-lg-4 col-10 mb-5'></div>
            <div className='row pb-5' id="mainPage3">
            
            <p id="myInfo">Info</p>
            <ul>
                <li>This webpage was made by me Lukas Otterström. Client in React.js, server in ASP.NET.</li>
                <li>Site is under construction.</li>
                <li>Below you can download my resume</li>
                <button className='btn btn-primary col-lg-1 col-md-3 col-5 mt-5' id="swedishCV" onClick={()=>{downLoad("sv")}}>In swedish</button>
                <button className='btn btn-primary col-lg-1 col-md-3 col-5 mx-lg-5 mx-4 mt-5' id="englishCV" onClick={()=>{downLoad("en")}}>In english</button>
            </ul>
            </div>
        </div>
        </>
    );
  }