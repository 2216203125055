import SlideShow from "../slideshow";

const downLoad = () =>{
    window.location.href = 'https://lukasotterstrom.com/files/jobbannons.png'
};


export default function PortfolioPage(){
    return (
        <>
       
        <SlideShow/>
        <div id="mainPage">
            <p className="text-center pb-4" style={{fontSize: "50px", width: "50%"}}>Portfolio</p>
            <h3 className="mt-5">Vad är en systemvetare?</h3>
            <p className="mt-4" style={{width: "50%"}}>Det kan ibland vara svårt att förstå vad en systemvetare gör. Vissa kanske tror att man besitter enorma kunskaper om teknik, 
            andra kanske ser vår linje som mindre teknisk och mer orienterad gentemot organisation och människa.
            Om man enbart granskar namnet syftar det på någon som har kunskaper inom system. 
            Men vad är ett system? Jag vill gärna relatera den frågan till den "heliga triaden" vi lagt mycket fokus och energi på under denna utbildning: Människa, Organisation & Teknik.
            Samtliga begrepp hör samman och arbetar i en slags allians inom IT-fältet. För att besvara på frågan jag ställde innan så besitter även alla dessa tre områden olika slags system.
            Det tekniska systemet kan självklart syfta till något slags datorsystem, men kan även peka på strukturer eller etablerade tillvägagångssätt inom IT. Hos organisationen kan system peka på organisationsstrukturer
            eller kommunikationsstrukturer. Hos människan kan system i princip hänvisas till alla våra komplexa uppsättningar av beteenden och drag.
            </p>
            <p style={{width: "50%"}}>Så svaret på frågan blir helt enkelt: det beror på! Ett mer konkret svar är att vi är just systemvetare. Med system så syftas på alla system som på ett eller annat sätt relaterar
                till människa, organisation och teknik i samspel. Genom att ha skapat oss en översiktlig kunskap kring samtliga av dessa områden, anammar vi rollen som spindeln i nätet. En liten 
                varelse som kan arbeta cross-organizational och förmedla kommunikation mellan avdelningar som annars har svårt att nå fram till varandra. Vi är den sociala nörden. Kontorets
                 kameleont som anpassar sina kunskaper efter det behov som finns. En yrkesroll som kan bidra med sin kompetens på ett brett plan. Detta är just vad som gör vår utbildning och vårt yrke så spännande och dynamiskt.
            </p>
            <h3 className="mt-5">Vilka kompetenser skapar sig en systemvetare?</h3>
            <p className="mt-4" style={{width: "50%"}}>Med tanke på vår breda inriktning har vi många färdigheter att redovisa. Personligen för mig har systemutvecklingen varit i fokus och detta är någonting
            jag är mycket glad över att ha fått färdigheter inom utifrån mina studier. Utanför systemutvecklingen och på andra sidan spektrar av det vi har fått möjligheten att läsa och skapa kunskap inom
             finner vi management-spåret. Denna inriktning inom det systemvetenskapliga programmet har givit mina klasskamrater möjligheten att specialisera sig inom områden som ledarskap, HR, entreprenörskap och företagsetik.
             Jag hoppas att mitt budskap här når fram och läsaren får en idé om hur brett ämnet är.</p>
             <p style={{width: "50%"}}>Nedan vill jag redovisa mina praktiska kunskaper som jag framförallt har skapat och som jag vet kommer vara av användning i yrkeslivet.</p>
             <img className="mt-4" style={{width: "50%"}} src="/portfolio.png" />
             <div className="row mt-5"><h1 className="col-2">Jobbannons</h1>
             <div className="col-2 mt-2">
                <button className="btn btn-primary" onClick={()=>{downLoad()}}>Ladda ner jobbannons</button>
                </div>
                </div>
                <h3 className="mt-5">Min kompetensprofil</h3>
                <p style={{width: "50%"}} className="mt-4">
                    Mitt namn är Lukas Otterström och den titel jag vill använda är webbutvecklare. Det är mitt största intresse inom fältet och det är det jag vill
                    arbeta och lägga ner tid på. Jag tycker att det är fantastiskt roligt att få utveckla inom webbmiljöer, både med front-end och back-end. Det jag dock
                    är främst inriktad på nu är design och användarupplevelse. Jag har under mina år på Linköpings Universitet genomfört flera kurser
                     där jag lärt mig vikten av ett smidigt och snyggt gränssnitt. Inte minst till användarens behag, utan även till mig själv som en 
                     slags bekräftelse, att jag klarar att skapa snygga och funktionella gränssnitt.
                </p>
                <p style={{width: "50%"}}>Jag har  under min tid vid Linköpings Universitet genomfört flertalet kurser som berör systemutveckling ur många aspekter, både frontend och backend. 
                Jag har även genomfört kurser som berör projektledning och agilt arbete. Kombinationen som det systemvetenskapliga programmet vid Linköpings Universitet erbjuder, anser jag ha
                gett mig en flexibel och kompatibel profil där helheten gällande teknik, människa och organisation har blivit tydlig. Jag förstår vikten av att fokusera på områden utanför det tekniska
                och därav även lägga fokus på aspekter som påverkar eller påverkas av tekniken.</p>
                <p style={{width: "50%"}}>Utöver ett öga för design och gedigen kunskap inom JavaScript, React och andra JS-ramverk har jag även goda kunskaper inom andra
                    programmeringsspråk. Inte nog med det besitter jag en god teknisk kunskap och förmågan att se helheten över ett system. Att kunna förstå sammanhanget och
                    hur olika delar av ett system hänger ihop har varit väldigt viktigt för mig att förstå. Därav har jag även skapat mig kunskaper inom C# och .NET för att förstå
                    vad som föregår "under huven", bakom användargränssnittet. Jag tror därför att kombinationen av min förmåga för god design och min förståelse av hela programmeringsstacken
                    skulle göra mig en mycket god kandidat för denna roll.
                </p>
                <h3 className="mt-5">Min personliga profil</h3>
                <p style={{width: "50%"}} className="mt-4">
                    Jag är en person som just nu befinner mig i början av min karriär. Trots detta är jag mycket karriärsinriktad och kan knappt bärga mig inför att börja min resa i yrkeslivet.
                    Detta medför ett stort driv i mig att få redovisa mina kunskaper och idéer på en verklig arbetsplats och få insikter kring hur det jag studerat i 3 år faktiskt genomförs i praktiken.
                    Att få ansvar och utifrån det redovisa goda resultat gentemot en arbetsgivare är någonting jag upplevt innan och tagit på stort allvar. Dock finns det inom detta allvar ett stort utrymme 
                    för stolthet och ambition att nå ännu längre. Jag tror att mitt driv kombinerat med min arbetsmoral och min passion till systemutveckling kommer att göra mig utmärkt för denna roll.
                </p>
                <p className="pb-5" style={{width: "50%"}}>
                    Mitt liv har präglats av problemlösning, teknik och design. Enda sedan barnsben har pussel, rubikskuber och lego varit en del av min vardag och jag har alltid sökt nya utmaningar som jag 
                    kanske i stunden inte klarat av men som jag ändå gett mig in på. Denna nyfikenhet och utmaningsförmåga jag besitter tror jag är mycket användbar i mitt yrke som systemutvecklare. Jag tror
                    även att min förmåga att arbeta dynamiskt kommer vara till fördel för mig. Jag besitter även en god social förmåga och gillar att arbeta tillsammans med kollegor inom projekt. Jag vill här beskriva
                    mig som en social kameleont som utan problem kan anpassa sig efter behov och finnas till där det behövs.
                </p>
                
        </div>
        </>
    );
}