import React from 'react';
import $ from 'jquery';
import * as openpgp from 'openpgp';

// async function encryptData(data) {
//   try {
//     const response = await fetch('https://lukasotterstrom.com/api/user/scrypts/public');
    
//     const publicKeyArmored = await response.text();

//     const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
//     const encrypted = await openpgp.encrypt({
//       message: await openpgp.createMessage({ text: JSON.stringify(data) }),
//       encryptionKeys: publicKey
//     });
//     return encrypted;
//   } catch (error) {
    
//   }
// }
function encryptData(data) {
  return fetch('https://lukasotterstrom.com/api/user/scrypts/public')
    .then(response => response.text())
    .then(publicKeyArmored => {
      return openpgp.readKey({ armoredKey: publicKeyArmored });
    })
    .then(publicKey => {
      return openpgp.createMessage({ text: JSON.stringify(data) })
        .then(message => {
          return openpgp.encrypt({
            message: message,
            encryptionKeys: publicKey
          });
        });
    })
    .then(encryptedData => {
      return encryptedData;
    })
    .catch(error => {
      console.error(error);
    });
}

async function handleLogin(event) {
  event.preventDefault();
  const userNameVal = document.getElementById('usernamelogin').value;
  const passWordVal = document.getElementById('passwordlogin').value;

  const data = {
    "Username": userNameVal,
    "Password": passWordVal
  };

    const encrypted = await encryptData(data);
 
    if (userNameVal !== "" && passWordVal !== ""){
      fetch('https://lukasotterstrom.com/api/user/login', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(encrypted)
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401 || response.status === 500) {
            
            throw new Error("Wrong login details.");
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then(data => {
          alert("Log in successful!");
          localStorage.setItem("token", data.token);
          $('#loginPop').css('opacity', '0');
          $('#loginPop').css('pointer-events', 'none');
        })
        .catch(error => {
          alert(error.message);
        });
    } else {
      alert("Please fill both fields.");
    }
}

function LoginTabContent(){
return <>
     
  <div className='container-fluid col-lg-10 col-md-10 col-12 mt-5 mb-4' style={{color: "white"}}>
  
  <h2 style={{color: "white"}}>Log In</h2>  
  <form onSubmit={handleLogin}>
    <div className="form-group mt-4">
      <label htmlFor="username">Username</label>
      <input type="text" className="form-control mt-2" id="usernamelogin" name="username" />
    </div>
    <div className="form-group mt-4">
      <label htmlFor="password">Password</label>
      <input type="password" className="form-control mt-2" id="passwordlogin" name="password" />
    </div>
    <button type="submit" className="btn btn-primary mt-4">Login</button>
  </form>
</div>
</>;
}

function LoginPage(props) {
  const closeLogin = () =>{
    $('#loginPop').css('opacity', '0');
    $('#loginPop').css('pointer-events', 'none');
  };

    return (
      
      <div id="loginPop">
      <div id="loginwindow" className='col-lg-3 col-md-6 col-11 container-fluid position-relative'>
        <div onClick={closeLogin}>
      <i className='fa fa-times' style={{color: "white", fontSize: "70px", position: "absolute", top: "10px", right: "20px",cursor: "pointer"}}></i>
      </div>
        <LoginTabContent/>
      </div>
    </div>
    
      );
  };

  export default LoginPage;